.quote-images-container {
}

.preview-items {
  display: flex;
  flex-wrap: wrap;
}

.quote-images-item {
  position: relative;
  width: 250px;
  height: 325px;
  overflow: hidden;
  align-items: center;
  /* display: flex; */
  background: white;
  border: 2px dotted #ccc;
  padding: 5px;
  margin: 5px;
}

.quote-images-item img {
  max-width: 100%;
}

.quote-images-caption {
  font-size: 11px;
}
