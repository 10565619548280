/**
 * Global Styles
**/
html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Nunito", "Lato", sans-serif;
  font-size: 16px;
  background: #edebe6;
  margin: 0;
  padding: 0;
}

.ui.button {
  font-family: "Nunito", "Lato", sans-serif;
}

button,
input {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: #cd1313;
}
a:hover {
  text-decoration: underline;
}

.root,
#root {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.home-container {
  flex: 0 0 50px;
}

.section-wrapper {
}

.section {
  position: relative;
  padding-top: 2rem;
  display: flex;
  flex-direction: column-reverse;
}
@media only screen and (min-width: 768px) {
  .section {
    flex-direction: column;
  }
}

.content-panel {
  flex: 1;
}

h1,
h2,
h3 {
  margin: 0 0 1rem 0;
  font-weight: normal;
}

h3 {
  color: #c82222;
}

ul.action-list {
  padding: 0;
}
ul.action-list li {
  display: inline;
  padding: 0px 5px;
}

/* mobile only */
@media only screen and (min-width: 480px) {
  .mobile-only {
    display: none;
  }
}
