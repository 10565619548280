/**
 * Buttons
**/

button {
  border: 0;
  outline: 0;
  font-size: 1rem;
  background: #c82222;
  color: white;
  border-radius: 5px;
  padding: 0.75rem;
  cursor: pointer;
  margin: 0.5rem;
}
button:hover {
  background: #820a0a;
}

.btn {
  padding: 5px;
}

.btnCreateQuote {
  background: #11ab11;
}
.btnCreateQuote:hover {
  background: #0a740a;
}
.btnEditQuote {
  background: orange;
}

.btn-link {
  background: none;
  color: #c82222;
  padding: 0;
  font-size: inherit;
}
.btn-link:hover {
  background: none;
  text-decoration: none;
}

.tag-status {
  display: block;
}

.btn-manager-approval-needed {
  border-radius: 5px;
  color: white;
  background: #ef223a;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-approval-needed {
  border-radius: 5px;
  color: white;
  background: #ffa10f;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-denied {
  border-radius: 5px;
  color: white;
  background: #cc0f0f;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-in-progress {
  border-radius: 5px;
  color: white;
  background: #5a96ff;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-approved {
  border-radius: 5px;
  color: white;
  background: #24a924;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-ready-for-review {
  border-radius: 5px;
  color: white;
  background: #f2c10e;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-quote-completed {
  border-radius: 5px;
  color: white;
  background: #00b5ad;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn--create {
}
