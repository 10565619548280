.quotesList {
  width: 100%;
}

.quotesList table {
  width: 100%;
}

.quotesList table th {
  text-align: left;
}

.quoteItemRow {
}

.quotes-list-container {
  font-size: 14px;
}

.addLineItem {
  text-align: right;
}

.quote-edit {
  font-size: 1rem;
  font-size: inherit;
}

th.quote-item-name {
  text-align: center;
  width: 110px;
  text-align: center;
}

th.quote-item-status {
  width: 180px;
}

th.quote-term {
  text-align: center;
  width: 80px;
  text-align: center;
}

th.quote-item-created {
  text-align: center;
  width: 120px;
  text-align: center;
}

th.quote-item-action {
  text-align: center;
  width: 150px;
  text-align: center;
}

.quote-title-column {
}

.quote-title-column .label {
  width: 150px;
}

.quote-notes-label {
  width: 150px;
}
.quote-notes,
.quote-notes textarea {
  width: 100%;
}

.quote-notes textarea {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
}

.quote-notes textarea::-webkit-input-placeholder,
.quote-notes textarea:-moz-placeholder,
.quote-notes textarea::-moz-placeholder,
.quote-notes textarea:-ms-input-placeholder,
.quote-notes textarea::placeholder {
  color: #c7c7c7;
}

.quote-section {
  background: #f7f7f7;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0;
}

.quote-btn {
  background: none;
  padding: 5px;
  font-size: 0.875rem;
  color: #c82222;
}

.quote-btn.edit {
}

.cc-me {
  max-width: 150px;
  margin: 2rem auto;
}

ul.action-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.action-links li {
  text-align: center;
  display: block;
  padding: 0 0.25rem;
  margin: 1rem 0;
}

ul.action-links li a,
ul.action-links .btn-link {
  display: block;
  background: #cd1313;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

ul.action-links li:last-child {
  border-right: none;
}

ul.action-links .btn-link {
  cursor: pointer;
}
ul.action-links .btn-link:hover {
  color: #1e70bf;
  text-decoration: underline;
}

@media only screen and (min-width: 1280px) {
  ul.action-links {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.action-links li {
    text-align: center;
    display: inline-block;
    padding: 0 0.5rem;
    border-right: 1px solid #ccc;
  }

  ul.action-links li a,
  ul.action-links .btn-link {
    display: block;
    color: #cd1313;
    background: white;
    border-radius: 0px;
    padding: 0px;
  }

  ul.action-links li:last-child {
    border-right: none;
  }
}

@media only screen and (max-width: 767px) {
  .ui.table {
    border: none;
  }
  .ui.table:not(.unstackable) tr {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 2rem 0;
  }
}

/**
 * QuoteTotal.js
**/
.quote-total {
  padding: 1rem 0;
  font-size: 1.5rem;
  text-align: right;
}

.quote-approval-needed {
  font-size: 1rem;
  color: red;
  margin: 1rem 0;
}

.quote-select-user {
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

.quote-select-user .FormInput {
  width: 300px;
}

/**
 * Top Panel
**/

.actions-top-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.actions-top-panel .left-actions {
  margin-right: auto;
}

.actions-top-panel .item {
  margin: 0.5rem 0;
}

/**
 * Media Queries
**/

@media (max-width: 480px) {
  .quote-item-description {
    display: none;
  }
  .quote-item-status {
    display: none;
  }
}

.submit-quote-button.ui.button {
  margin-top: 3rem;
}

.remove {
  color: #c82222;
  cursor: pointer;
}

.client-copy-link {
  width: 100%;
  padding: 0.25rem;
  background: #e3e3e3;
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  padding: 0.8rem;
  font-size: 0.9rem;
  resize: none;
}
