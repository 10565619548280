/**
 * Dropzone Uploader
**/
.uploader-container {
  padding: 1rem;
}

.dropzone-uploader {
  width: 100%;
  height: 400px;
  border: 2px dotted #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #252525;
}

.dropzone-uploader:hover {
  background: #e6f9ff;
  cursor: pointer;
  border: 3px dotted #666;
}

/**
 * Preview
**/
.uploader-preview-container {
  margin-top: 1rem;
}

.uploader-preview-items {
}

.uploader-preview-item {
}

.uploader-preview-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 35px;
  text-align: center;
  cursor: pointer;
}

.uploader-preview-close:before {
  font-family: "Ionicons";
  content: "\f2d7";
  font-size: 2rem;
}

.uploader-preview-item img {
  max-width: 100%;
}

.uploader-preview-image {
  max-width: 250px;
}
