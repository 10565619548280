@import url(/react-vis/dist/style.css);

.login-container {
  height: 100%;
  display: flex;
  flex: 1 1;
}

.login-left {
  background: url(/static/media/hero-11.61f8684f.jpg) no-repeat 40% center;
  background-size: cover;
  flex: 1 1;
}

.login-right {
  box-shadow: -3px 0px 10px #666;
  flex: 1 1;
  display: flex;
}


.login-overlay {
  /*background: rgba(66, 66, 66, 0.6);*/
  width: 100%;
}

.login-box {
  /*background: rgba(255, 255, 255, 0.95);*/
  padding: 1rem;
  border-radius: 5px;
  align-self: center;
}

.login-box .textInput {
  display: block;
  font-size: 1.25rem;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 0.5rem;
  margin: 0 0.5rem 1rem 0;
  outline: 0;
  width: 300px;
}

.btnLogin {
  border: 0;
  outline: 0;
  font-size: 1.1rem;
  background: #c82222;
  color: white;
  float: right;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}

.btnLogin:hover {
  background: red;
}

.admin-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  position: relative;
}

.admin-container {
  display: flex;
  flex: 1 1;
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
  .admin-container {
    overflow-x: scroll;
  }
}

.admin-container .content-container {
  background: white;
  padding: 2rem 1rem 1rem;
  width: 100%;
  padding-top: 30px;
}

/**
 * Mobile Menu
**/

.mobile-burger-menu {
  position: absolute;
  font-family: "Ionicons";
  background: white;
  color: black;
  font-size: 2rem;
  top: 0;
  left: 0;
  width: 50px;
  text-align: center;
  height: 48px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 769px) {
  .mobile-burger-menu {
    display: none;
  }
}

.mobile-menu {
  position: absolute;
  background: white;
  height: 100vh;
  top: 48px;
  z-index: 10;
  width: 100%;
  left: -100%;
  transition: left 0.5s ease-in-out;
}

.mobile-menu.mobile-menu-true {
  left: 0;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0px 3px 10px 1px #999;
}

.mobile-menu li {
  border-bottom: 1px solid gray;
}

.mobile-menu li a {
  font-size: 1.5rem;
  color: #637282;
  display: block;
  padding: 1rem;
  text-decoration: none;
}

/**
 * Logo
**/

.logo {
}

/**
 * Search
**/

.portal-search-bar {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50%;
}

.admin-top-wrapper {
  background: #ca0c22;
  padding: 0;
  height: 64px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .admin-top-wrapper {
    flex-direction: row;
  }
}

.logo {
  padding-top: 10px;
  padding-left: 64px;
}

.universal-search-container {
  margin-left: auto;
  padding-right: 1rem;
  padding-top: 1rem;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
  overflow: scroll;
  height: 600px;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2000000;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 1px dashed #ccc;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 18px;
  color: black;
}

.suggestion-content {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
  background-repeat: no-repeat;
}

.suggestion-1-col {
  width: 100%;
}
.suggestion-content-header {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}
.suggestion-content-text {
  font-size: 12px;
  width: 50%;
}

.highlight {
  color: #ee0000;
  font-weight: bold;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

.navigation-email {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 16px;
  line-height: 16px;
  position: relative;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-left: 0px;
  padding: 20px 16px 20px 72px;
}

.navigation-email-circle {
  color: rgb(255, 255, 255);
  background-color: rgb(188, 188, 188);
  -webkit-user-select: none;
          user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 8px;
  left: 16px;
}

.navigation-email-icon {
  color: rgb(255, 255, 255);
  background-color: rgb(188, 188, 188);
  -webkit-user-select: none;
          user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 8px;
  left: 16px;
}

.sidebar .navigation-email-circle svg {
  fill: white;
  margin: 0;
  position: initial;
}

.SearchBar--container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchBar--text {
  margin: 0 0.25rem 0 0;
  width: 100%;
}

.SearchBar--button {
}

.SearchBar--form {
  display: flex;
  flex: 1 1;
}

/**
 * Filters
**/

.filters-panel {
  border-radius: 5px;
  display: flex;
  background: #e6e6e6;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  visibility: hidden;
}

.filters-panel.open {
  visibility: visible;
}

.filters-panel .dropdown {
  flex: 1 1;
  margin: 0 1rem;
}

@media only screen and (max-width: 1280px) {
  .filters-panel {
    flex-direction: column;
  }
}

.pagination-container {
  margin-top: 1rem;
}

.pagination-container a {
  display: block;
  padding: 0.5rem 1rem;
}

.pagination-container a:hover {
  background: #db2828;
  color: #fff;
  text-decoration: none;
}

.ui.menu .item.pagination-item {
  padding: 0;
  justify-content: center;
  cursor: pointer;
}

.ui.menu .item.pagination-item.active {
  padding: 0;
}

.quotesList {
  width: 100%;
}

.quotesList table {
  width: 100%;
}

.quotesList table th {
  text-align: left;
}

.quoteItemRow {
}

.quotes-list-container {
  font-size: 14px;
}

.addLineItem {
  text-align: right;
}

.quote-edit {
  font-size: 1rem;
  font-size: inherit;
}

th.quote-item-name {
  text-align: center;
  width: 110px;
  text-align: center;
}

th.quote-item-status {
  width: 180px;
}

th.quote-term {
  text-align: center;
  width: 80px;
  text-align: center;
}

th.quote-item-created {
  text-align: center;
  width: 120px;
  text-align: center;
}

th.quote-item-action {
  text-align: center;
  width: 150px;
  text-align: center;
}

.quote-title-column {
}

.quote-title-column .label {
  width: 150px;
}

.quote-notes-label {
  width: 150px;
}
.quote-notes,
.quote-notes textarea {
  width: 100%;
}

.quote-notes textarea {
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
}

.quote-notes textarea::-webkit-input-placeholder,
.quote-notes textarea:-moz-placeholder,
.quote-notes textarea::-moz-placeholder,
.quote-notes textarea:-ms-input-placeholder,
.quote-notes textarea::placeholder {
  color: #c7c7c7;
}

.quote-section {
  background: #f7f7f7;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0;
}

.quote-btn {
  background: none;
  padding: 5px;
  font-size: 0.875rem;
  color: #c82222;
}

.quote-btn.edit {
}

.cc-me {
  max-width: 150px;
  margin: 2rem auto;
}

ul.action-links {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.action-links li {
  text-align: center;
  display: block;
  padding: 0 0.25rem;
  margin: 1rem 0;
}

ul.action-links li a,
ul.action-links .btn-link {
  display: block;
  background: #cd1313;
  color: white;
  border-radius: 10px;
  padding: 10px;
}

ul.action-links li:last-child {
  border-right: none;
}

ul.action-links .btn-link {
  cursor: pointer;
}
ul.action-links .btn-link:hover {
  color: #1e70bf;
  text-decoration: underline;
}

@media only screen and (min-width: 1280px) {
  ul.action-links {
    display: flex;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.action-links li {
    text-align: center;
    display: inline-block;
    padding: 0 0.5rem;
    border-right: 1px solid #ccc;
  }

  ul.action-links li a,
  ul.action-links .btn-link {
    display: block;
    color: #cd1313;
    background: white;
    border-radius: 0px;
    padding: 0px;
  }

  ul.action-links li:last-child {
    border-right: none;
  }
}

@media only screen and (max-width: 767px) {
  .ui.table {
    border: none;
  }
  .ui.table:not(.unstackable) tr {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 2rem 0;
  }
}

/**
 * QuoteTotal.js
**/
.quote-total {
  padding: 1rem 0;
  font-size: 1.5rem;
  text-align: right;
}

.quote-approval-needed {
  font-size: 1rem;
  color: red;
  margin: 1rem 0;
}

.quote-select-user {
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

.quote-select-user .FormInput {
  width: 300px;
}

/**
 * Top Panel
**/

.actions-top-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.actions-top-panel .left-actions {
  margin-right: auto;
}

.actions-top-panel .item {
  margin: 0.5rem 0;
}

/**
 * Media Queries
**/

@media (max-width: 480px) {
  .quote-item-description {
    display: none;
  }
  .quote-item-status {
    display: none;
  }
}

.submit-quote-button.ui.button {
  margin-top: 3rem;
}

.remove {
  color: #c82222;
  cursor: pointer;
}

.client-copy-link {
  width: 100%;
  padding: 0.25rem;
  background: #e3e3e3;
  border-radius: 5px;
  border: 1px solid #c9c9c9;
  padding: 0.8rem;
  font-size: 0.9rem;
  resize: none;
}

.add-item-container {
  margin: 1rem 0;
}

.add-item-container .FormInput {
  flex: 1 1;
}

.add-item-container .FormInput.location,
.add-item-container .FormInput.description {
  /*flex-basis: 300px;*/
}

.FormError {
  font-size: 13px;
  font-style: italic;
  color: red;
}

/**
 * Media Queries
**/

@media (max-width: 480px) {
  .ui.form .fields.form-group {
    flex-direction: column;
  }
  /* .add-item-container {
    flex-wrap: wrap;
  }
  .add-item-container .FormInput {
    flex: 1 1 auto;
  }
  .add-item-container .FormInput.location,
  .add-item-container .FormInput.description {
    flex-basis: initial;
  } */
}

.new-customer-content {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 481px) {
  flex-direction: row;
}

.new-customer-container {
}

.new-customer-container .label {
  width: 150px;
}

.primary {
  flex: 1 1;
}

.secondary {
  flex: 1 1;
}

.company-search {
  margin-bottom: 1rem;
}

/**
 * Customers Table
**/

@media (max-width: 480px) {
  .customers-row-company {
    width: 30%;
  }

  .customers-row-name {
    width: 40%;
  }

  .customers-row-email {
    display: none;
  }
}

/**
 * Dropzone Uploader
**/
.uploader-container {
  padding: 1rem;
}

.dropzone-uploader {
  width: 100%;
  height: 400px;
  border: 2px dotted #666;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #252525;
}

.dropzone-uploader:hover {
  background: #e6f9ff;
  cursor: pointer;
  border: 3px dotted #666;
}

/**
 * Preview
**/
.uploader-preview-container {
  margin-top: 1rem;
}

.uploader-preview-items {
}

.uploader-preview-item {
}

.uploader-preview-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 35px;
  text-align: center;
  cursor: pointer;
}

.uploader-preview-close:before {
  font-family: "Ionicons";
  content: "\F2D7";
  font-size: 2rem;
}

.uploader-preview-item img {
  max-width: 100%;
}

.uploader-preview-image {
  max-width: 250px;
}

.view-quote-container {
  background: white;
  font-family: Arial;
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  box-shadow: 0px 4px 4px #ccc;
}

.view-quote-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 2rem;
}

.ui.breadcrumb.quote-breadcrumbs {
  margin: 1.5rem 0;
}

.tbl-quote-header {
  width: 100%;
  line-height: 1.6rem;
}

.tbl-quote-header td {
  width: 50%;
  display: block;
}

@media only screen and (min-width: 480px) {
  .tbl-quote-header td {
    display: table-cell;
  }
}

.tbl-job-details {
  text-align: left;
  margin-top: 2rem;
}

@media only screen and (min-width: 480px) {
  .tbl-job-details {
    padding-left: 15%;
    margin-top: 2rem;
  }
}

/**
 * Main Quote
**/
.tbl-quote {
  width: 100%;
  border-collapse: collapse;
  line-height: 2rem;
  margin-top: 20px;
}

.tbl-quote thead {
  display: none;
}

.tbl-quote th {
  font-weight: bold;
  text-align: left;
}

.tbl-quote tr {
  margin: 1rem 0;
  display: block;
  padding: 1rem;
}

.tbl-quote tr:nth-child(odd) {
  background: #e1f4ff;
}

.tbl-quote td {
  margin: 0;
  display: block;
}

@media only screen and (min-width: 480px) {
  .tbl-quote td {
    display: table-cell;
  }

  .tbl-quote thead {
    display: table-header-group;
  }

  .tbl-quote tr {
    margin: 0;
    display: table-row;
    padding: 0;
  }
}

.tbl-quote tbody {
}

.quotes-notes-container {
  margin: 2rem 0;
}

.quotes-notes-container label {
  font-weight: bold;
}
.quotes-notes {
}

.quotes-acceptance {
  text-align: center;
}

.tbl-quote .col-line-no {
  display: none;
}

@media only screen and (min-width: 480px) {
  .tbl-quote .col-line-no {
    display: table-cell;
  }
}

.watson-container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.watson-header {
  font-size: 18px;
  font-weight: bold;
  line-height: 14px;
}
.watson-subheader {
  font-weight: bold;
  font-size: 14px;
}

#line-header td {
  background: #000000;
  color: #ffffff;
}

.watson-column {
  width: 30%;
}

.watson-table {
  width: 100%;
  position: relative;
}

.watson-client-wrapper {
  margin-top: 20px;
}

.watson-company-name {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0px;
  font-weight: bold;
}

.watson-approval {
  margin: 0 auto;
  width: 90%;
}
.watson-approval label {
  font-weight: bold;
  display: inline-block;
}

.watson-square {
  width: 30px;
  height: 25px;
  border: 3px solid #000;
}
.watson-approver-line {
  border-bottom: 1px solid #000;
  margin-right: 4px;
}

.watson-date-line {
  border-bottom: 1px solid #000;
}

/* watson line items */

.watson-line-header {
  margin-top: 50px;
  width: 100%;
}

.watson-line-header td {
  padding: 1px;
}

.watson-line-item {
  width: 100%;
  position: relative;
  padding: 3px 0;
  line-height: 1.3em;
}

.col-line-number {
  width: 5%;
}
.col-location {
  position: relative;
  width: 19%;
  padding-left: 6px;
}
.col-desc {
  position: relative;
}
.col-quantity {
  position: relative;
  width: 10%;
}
.col-unit {
  position: relative;
  width: 10%;
}
.col-unit-cost {
  position: relative;
  width: 10%;
}
.col-total {
  position: relative;
  width: 10%;
}

.watson-total {
  margin-top: 50px;
  text-align: right;
}

.watson-quote-notice {
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;
}

.watson-notes {
  margin-top: 20px;
}

.watson-receipt {
  text-align: center;
  margin: 20px 0;
}

/* styles */
.watson-hdr-black {
  background-color: black;
  color: #fff;
  display: block;
}

.watson-bold {
  font-weight: bold;
}

.watson-padded {
  padding: 0 2px 4px 4px;
}

.clear {
  clear: both;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.quote-images-container {
}

.preview-items {
  display: flex;
  flex-wrap: wrap;
}

.quote-images-item {
  position: relative;
  width: 250px;
  height: 325px;
  overflow: hidden;
  align-items: center;
  /* display: flex; */
  background: white;
  border: 2px dotted #ccc;
  padding: 5px;
  margin: 5px;
}

.quote-images-item img {
  max-width: 100%;
}

.quote-images-caption {
  font-size: 11px;
}

.quote-messages-container {
  margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
  .quote-messages-container {
    margin-right: 200px;
  }
}

.quote-actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .quote-actions-container {
    flex-direction: row;
  }
}

.action-panel {
  flex: 1 1;
}

@media only screen and (min-width: 768px) {
  .action-panel {
    flex: 0 0 180px;
    margin-right: 40px;
    padding: 0 0 0 20px;
  }
}

.action-panel button {
  padding: 5px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .action-panel button {
    width: 200px;
  }
}

.action-panel .item {
  margin: 10px 0;
  display: block;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .action-panel .item {
    width: auto;
  }
}

.actions-dropdown-container,
.actions-dropdown.ui {
  width: 100%;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .actions-dropdown-container,
  .actions-dropdown.ui {
    width: auto;
    text-align: initial;
  }
}

.action-panel .submit-quote {
  margin-left: auto;
}

.additional-approvers-container {
}

.additional-approvers-container .users {
  display: flex;
  flex-direction: column;
}

.additional-approvers-container .user {
  border-radius: 5px;
  background: #f6f9fc;
  margin-bottom: 2rem;
  margin-right: auto;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
}

.additional-approvers-container .user .name {
  font-weight: bold;
}

.additional-approvers-container .user .company {
}

.additional-approvers-container .user .email {
}


.table-group {
  margin: 4rem 0;
}

.table-group .inverted a {
  color: #fff;
}

.dashboard-header {
  display: flex;
}

section.dashboard-section {
  background: #f5f5f5;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #ebebeb;
}

.dashboard-charts-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.dashboard-charts-container .item {
  padding: 1rem;
  margin: 2rem;
}

.dashboard-header .header {
  flex: 1 1;
}

.jump-to-quotes {
  flex: 0 0 auto;
}

.counts-container {
  text-align: center;
}

.counts-container .item {
  display: inline-block;
  width: 700px;
  padding: 1rem;
  margin: 2rem;
}

.counts-container .item-full {
  width: 1000px;
}

.report-item {
  background: #f4f4f4;
  border-radius: 5px;
}

.supervisor-reports {
  border-top: 2rem solid #be0000;
  padding-top: 5rem;
  margin: 5rem 0;
}


.SearchPage--description {
  font-size: 12px;
  font-style: italic;
}

.SearchPage--container {
  border-bottom: 1px solid #ccc;
  padding: 2rem 0;
  margin: 2rem 0;
}

/**
 * Global Styles
**/
html,
body {
  height: 100%;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: "Nunito", "Lato", sans-serif;
  font-size: 16px;
  background: #edebe6;
  margin: 0;
  padding: 0;
}

.ui.button {
  font-family: "Nunito", "Lato", sans-serif;
}

button,
input {
  font-family: inherit;
}

a {
  text-decoration: none;
  color: #cd1313;
}
a:hover {
  text-decoration: underline;
}

.root,
#root {
  height: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.home-container {
  flex: 0 0 50px;
}

.section-wrapper {
}

.section {
  position: relative;
  padding-top: 2rem;
  display: flex;
  flex-direction: column-reverse;
}
@media only screen and (min-width: 768px) {
  .section {
    flex-direction: column;
  }
}

.content-panel {
  flex: 1 1;
}

h1,
h2,
h3 {
  margin: 0 0 1rem 0;
  font-weight: normal;
}

h3 {
  color: #c82222;
}

ul.action-list {
  padding: 0;
}
ul.action-list li {
  display: inline;
  padding: 0px 5px;
}

/* mobile only */
@media only screen and (min-width: 480px) {
  .mobile-only {
    display: none;
  }
}

/**
 * Buttons
**/

button {
  border: 0;
  outline: 0;
  font-size: 1rem;
  background: #c82222;
  color: white;
  border-radius: 5px;
  padding: 0.75rem;
  cursor: pointer;
  margin: 0.5rem;
}
button:hover {
  background: #820a0a;
}

.btn {
  padding: 5px;
}

.btnCreateQuote {
  background: #11ab11;
}
.btnCreateQuote:hover {
  background: #0a740a;
}
.btnEditQuote {
  background: orange;
}

.btn-link {
  background: none;
  color: #c82222;
  padding: 0;
  font-size: inherit;
}
.btn-link:hover {
  background: none;
  text-decoration: none;
}

.tag-status {
  display: block;
}

.btn-manager-approval-needed {
  border-radius: 5px;
  color: white;
  background: #ef223a;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-approval-needed {
  border-radius: 5px;
  color: white;
  background: #ffa10f;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-denied {
  border-radius: 5px;
  color: white;
  background: #cc0f0f;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-in-progress {
  border-radius: 5px;
  color: white;
  background: #5a96ff;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-approved {
  border-radius: 5px;
  color: white;
  background: #24a924;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-ready-for-review {
  border-radius: 5px;
  color: white;
  background: #f2c10e;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn-quote-completed {
  border-radius: 5px;
  color: white;
  background: #00b5ad;
  text-align: center;
  padding: 0.5rem 2rem;
}

.btn--create {
}


.Select,.Select-control {
    position: relative
}

.Select,.Select div,.Select input,.Select span {
    box-sizing: border-box
}

.Select.is-disabled>.Select-control {
    background-color: #f9f9f9
}

.Select.is-disabled>.Select-control:hover {
    box-shadow: none
}

.Select.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
    opacity: .35
}

.Select-control {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: 0;
    overflow: hidden;
    width: 100%
}

.is-searchable.is-focused:not(.is-open)>.Select-control,.is-searchable.is-open>.Select-control {
    cursor: text
}

.Select-control:hover {
    box-shadow: 0 1px 0 rgba(0,0,0,.06)
}

.Select-control .Select-input:focus {
    outline: 0
}

.is-open>.Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9
}

.is-open>.Select-control>.Select-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px
}

.is-focused:not(.is-open)>.Select-control {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 0 3px rgba(0,126,255,.1)
}

.Select--single>.Select-control .Select-value,.Select-placeholder {
    bottom: 0;
    color: #aaa;
    left: 0;
    line-height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.has-value.Select--single>.Select-control .Select-value .Select-value-label,.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value .Select-value-label {
    color: #333
}

.has-value.Select--single>.Select-control .Select-value a.Select-value-label,.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value a.Select-value-label {
    cursor: pointer;
    text-decoration: none
}

.has-value.Select--single>.Select-control .Select-value a.Select-value-label:focus,.has-value.Select--single>.Select-control .Select-value a.Select-value-label:hover,.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value a.Select-value-label:focus,.has-value.is-pseudo-focused.Select--single>.Select-control .Select-value a.Select-value-label:hover {
    color: #007eff;
    outline: 0;
    text-decoration: underline
}

.Select-input {
    height: 34px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle
}

.Select-input>input {
    width: 100%;
    background: none;
    border: 0;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: 0;
    line-height: 14px;
    padding: 8px 0 12px;
    -webkit-appearance: none
}

.Select-loading,.Select-loading-zone {
    width: 16px;
    position: relative;
    vertical-align: middle
}

.is-focused .Select-input>input {
    cursor: text
}

.has-value.is-pseudo-focused .Select-input {
    opacity: 0
}

.Select-control:not(.is-searchable)>.Select-input {
    outline: 0
}

.Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    text-align: center
}

.Select-loading {
    animation: Select-animation-spin .4s infinite linear;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-right-color: #333;
    display: inline-block
}

.Select-clear-zone {
    animation: Select-animation-fadeIn .2s;
    color: #999;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 17px
}

.Select-clear-zone:hover {
    color: #D0021B
}

.Select-clear {
    display: inline-block;
    font-size: 18px;
    line-height: 1
}

.Select--multi .Select-clear-zone {
    width: 17px
}

.Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 25px;
    padding-right: 5px
}

.Select--multi .Select-multi-value-wrapper,.Select-arrow {
    display: inline-block
}

.Select-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    height: 0;
    width: 0
}

.Select-arrow-zone:hover>.Select-arrow,.is-open .Select-arrow {
    border-top-color: #666
}

.Select .Select-aria-only {
    display: inline-block;
    height: 1px;
    width: 1px;
    margin: -1px;
    clip: rect(0,0,0,0);
    overflow: hidden
}

.Select-noresults,.Select-option {
    box-sizing: border-box;
    display: block;
    padding: 8px 10px
}

@keyframes Select-animation-fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.Select-menu-outer {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0,0,0,.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    -webkit-overflow-scrolling: touch
}

.Select-menu {
    max-height: 198px;
    overflow-y: auto
}

.Select-option {
    background-color: #fff;
    color: #666;
    cursor: pointer
}

.Select-option:last-child {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.Select-option.is-selected {
    background-color: #f5faff;
    background-color: rgba(0,126,255,.04);
    color: #333
}

.Select-option.is-focused {
    background-color: #ebf5ff;
    background-color: rgba(0,126,255,.08);
    color: #333
}

.Select-option.is-disabled {
    color: #ccc;
    cursor: default
}

.Select-noresults {
    color: #999;
    cursor: default
}

.Select--multi .Select-input {
    vertical-align: middle;
    margin-left: 10px;
    padding: 0
}

.Select--multi.has-value .Select-input {
    margin-left: 5px
}

.Select--multi .Select-value {
    background-color: #ebf5ff;
    background-color: rgba(0,126,255,.08);
    border-radius: 2px;
    border: 1px solid #c2e0ff;
    border: 1px solid rgba(0,126,255,.24);
    color: #007eff;
    display: inline-block;
    font-size: .9em;
    line-height: 1.4;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top
}

.Select--multi .Select-value-icon,.Select--multi .Select-value-label {
    display: inline-block;
    vertical-align: middle
}

.Select--multi .Select-value-label {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    cursor: default;
    padding: 2px 5px
}

.Select--multi a.Select-value-label {
    color: #007eff;
    cursor: pointer;
    text-decoration: none
}

.Select--multi a.Select-value-label:hover {
    text-decoration: underline
}

.Select--multi .Select-value-icon {
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: 1px solid #c2e0ff;
    border-right: 1px solid rgba(0,126,255,.24);
    padding: 1px 5px 3px
}

.Select--multi .Select-value-icon:focus,.Select--multi .Select-value-icon:hover {
    background-color: #d8eafd;
    background-color: rgba(0,113,230,.08);
    color: #0071e6
}

.Select--multi .Select-value-icon:active {
    background-color: #c2e0ff;
    background-color: rgba(0,126,255,.24)
}

.Select--multi.is-disabled .Select-value {
    background-color: #fcfcfc;
    border: 1px solid #e3e3e3;
    color: #333
}

.Select--multi.is-disabled .Select-value-icon {
    cursor: not-allowed;
    border-right: 1px solid #e3e3e3
}

.Select--multi.is-disabled .Select-value-icon:active,.Select--multi.is-disabled .Select-value-icon:focus,.Select--multi.is-disabled .Select-value-icon:hover {
    background-color: #fcfcfc
}

@keyframes Select-animation-spin {
    to {
        transform: rotate(1turn)
    }
}
/**
 * Tables
**/

.ui.table thead th.non-sortable-header {
  color: #666;
  font-weight: normal;
}

.sortable-header {
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.sortable-header:hover {
  text-decoration: underline;
}

.help-text {
  margin: 1rem 0;
  color: black;
  font-size: 1rem;
}

/**
 * Sidebar
**/

.sidebar {
  background-color: #f8fafb;
  width: 303px;
  padding-top: 8px;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

.sidebar svg {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  fill: rgb(117, 117, 117);
  height: 24px;
  width: 24px;
  -webkit-user-select: none;
          user-select: none;
  margin-right: 25px;
}

.sidebar ul {
  list-style: none;
  margin: 7px 0 0 15px;
  padding: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: 3px 0 3px 0;
}

.sidebar a {
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  line-height: 3rem;
  font-size: 16px;
  padding-left: 0.5rem;
  transition: padding-left 0.45s;
}

.sidebar a:hover {
  /*padding-left: 1rem;*/
  text-decoration: none;
  color: #cd1313;
}

