.pagination-container {
  margin-top: 1rem;
}

.pagination-container a {
  display: block;
  padding: 0.5rem 1rem;
}

.pagination-container a:hover {
  background: #db2828;
  color: #fff;
  text-decoration: none;
}

.ui.menu .item.pagination-item {
  padding: 0;
  justify-content: center;
  cursor: pointer;
}

.ui.menu .item.pagination-item.active {
  padding: 0;
}
