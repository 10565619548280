.watson-container {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
}

.watson-header {
  font-size: 18px;
  font-weight: bold;
  line-height: 14px;
}
.watson-subheader {
  font-weight: bold;
  font-size: 14px;
}

#line-header td {
  background: #000000;
  color: #ffffff;
}

.watson-column {
  width: 30%;
}

.watson-table {
  width: 100%;
  position: relative;
}

.watson-client-wrapper {
  margin-top: 20px;
}

.watson-company-name {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 0px;
  font-weight: bold;
}

.watson-approval {
  margin: 0 auto;
  width: 90%;
}
.watson-approval label {
  font-weight: bold;
  display: inline-block;
}

.watson-square {
  width: 30px;
  height: 25px;
  border: 3px solid #000;
}
.watson-approver-line {
  border-bottom: 1px solid #000;
  margin-right: 4px;
}

.watson-date-line {
  border-bottom: 1px solid #000;
}

/* watson line items */

.watson-line-header {
  margin-top: 50px;
  width: 100%;
}

.watson-line-header td {
  padding: 1px;
}

.watson-line-item {
  width: 100%;
  position: relative;
  padding: 3px 0;
  line-height: 1.3em;
}

.col-line-number {
  width: 5%;
}
.col-location {
  position: relative;
  width: 19%;
  padding-left: 6px;
}
.col-desc {
  position: relative;
}
.col-quantity {
  position: relative;
  width: 10%;
}
.col-unit {
  position: relative;
  width: 10%;
}
.col-unit-cost {
  position: relative;
  width: 10%;
}
.col-total {
  position: relative;
  width: 10%;
}

.watson-total {
  margin-top: 50px;
  text-align: right;
}

.watson-quote-notice {
  margin-top: 40px;
  text-align: center;
  text-transform: uppercase;
}

.watson-notes {
  margin-top: 20px;
}

.watson-receipt {
  text-align: center;
  margin: 20px 0;
}

/* styles */
.watson-hdr-black {
  background-color: black;
  color: #fff;
  display: block;
}

.watson-bold {
  font-weight: bold;
}

.watson-padded {
  padding: 0 2px 4px 4px;
}

.clear {
  clear: both;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}
