
.login-container {
  height: 100%;
  display: flex;
  flex: 1;
}

.login-left {
  background: url('./hero-11.jpg') no-repeat 40% center;
  background-size: cover;
  flex: 1;
}

.login-right {
  box-shadow: -3px 0px 10px #666;
  flex: 1;
  display: flex;
}


.login-overlay {
  /*background: rgba(66, 66, 66, 0.6);*/
  width: 100%;
}

.login-box {
  /*background: rgba(255, 255, 255, 0.95);*/
  padding: 1rem;
  border-radius: 5px;
  align-self: center;
}

.login-box .textInput {
  display: block;
  font-size: 1.25rem;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 0.5rem;
  margin: 0 0.5rem 1rem 0;
  outline: 0;
  width: 300px;
}

.btnLogin {
  border: 0;
  outline: 0;
  font-size: 1.1rem;
  background: #c82222;
  color: white;
  float: right;
  border-radius: 5px;
  padding: 0.5rem;
  cursor: pointer;
}

.btnLogin:hover {
  background: red;
}
