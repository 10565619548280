.add-item-container {
  margin: 1rem 0;
}

.add-item-container .FormInput {
  flex: 1;
}

.add-item-container .FormInput.location,
.add-item-container .FormInput.description {
  /*flex-basis: 300px;*/
}

.FormError {
  font-size: 13px;
  font-style: italic;
  color: red;
}

/**
 * Media Queries
**/

@media (max-width: 480px) {
  .ui.form .fields.form-group {
    flex-direction: column;
  }
  /* .add-item-container {
    flex-wrap: wrap;
  }
  .add-item-container .FormInput {
    flex: 1 1 auto;
  }
  .add-item-container .FormInput.location,
  .add-item-container .FormInput.description {
    flex-basis: initial;
  } */
}
