/**
 * Tables
**/

.ui.table thead th.non-sortable-header {
  color: #666;
  font-weight: normal;
}

.sortable-header {
  color: black;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
}

.sortable-header:hover {
  text-decoration: underline;
}

.help-text {
  margin: 1rem 0;
  color: black;
  font-size: 1rem;
}
