.admin-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
}

.admin-container {
  display: flex;
  flex: 1;
}

@media only screen and (min-width: 769px) and (max-width: 1440px) {
  .admin-container {
    overflow-x: scroll;
  }
}

.admin-container .content-container {
  background: white;
  padding: 2rem 1rem 1rem;
  width: 100%;
  padding-top: 30px;
}

/**
 * Mobile Menu
**/

.mobile-burger-menu {
  position: absolute;
  font-family: "Ionicons";
  background: white;
  color: black;
  font-size: 2rem;
  top: 0;
  left: 0;
  width: 50px;
  text-align: center;
  height: 48px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 769px) {
  .mobile-burger-menu {
    display: none;
  }
}

.mobile-menu {
  position: absolute;
  background: white;
  height: 100vh;
  top: 48px;
  z-index: 10;
  width: 100%;
  left: -100%;
  transition: left 0.5s ease-in-out;
}

.mobile-menu.mobile-menu-true {
  left: 0;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background: white;
  box-shadow: 0px 3px 10px 1px #999;
}

.mobile-menu li {
  border-bottom: 1px solid gray;
}

.mobile-menu li a {
  font-size: 1.5rem;
  color: #637282;
  display: block;
  padding: 1rem;
  text-decoration: none;
}

/**
 * Logo
**/

.logo {
}

/**
 * Search
**/

.portal-search-bar {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50%;
}
