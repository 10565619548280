.new-customer-content {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 481px) {
  flex-direction: row;
}

.new-customer-container {
}

.new-customer-container .label {
  width: 150px;
}

.primary {
  flex: 1;
}

.secondary {
  flex: 1;
}

.company-search {
  margin-bottom: 1rem;
}

/**
 * Customers Table
**/

@media (max-width: 480px) {
  .customers-row-company {
    width: 30%;
  }

  .customers-row-name {
    width: 40%;
  }

  .customers-row-email {
    display: none;
  }
}
