.SearchPage--description {
  font-size: 12px;
  font-style: italic;
}

.SearchPage--container {
  border-bottom: 1px solid #ccc;
  padding: 2rem 0;
  margin: 2rem 0;
}
