.admin-top-wrapper {
  background: #ca0c22;
  padding: 0;
  height: 64px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .admin-top-wrapper {
    flex-direction: row;
  }
}

.logo {
  padding-top: 10px;
  padding-left: 64px;
}
