.tbl-quote-header {
  width: 100%;
  line-height: 1.6rem;
}

.tbl-quote-header td {
  width: 50%;
  display: block;
}

@media only screen and (min-width: 480px) {
  .tbl-quote-header td {
    display: table-cell;
  }
}

.tbl-job-details {
  text-align: left;
  margin-top: 2rem;
}

@media only screen and (min-width: 480px) {
  .tbl-job-details {
    padding-left: 15%;
    margin-top: 2rem;
  }
}

/**
 * Main Quote
**/
.tbl-quote {
  width: 100%;
  border-collapse: collapse;
  line-height: 2rem;
  margin-top: 20px;
}

.tbl-quote thead {
  display: none;
}

.tbl-quote th {
  font-weight: bold;
  text-align: left;
}

.tbl-quote tr {
  margin: 1rem 0;
  display: block;
  padding: 1rem;
}

.tbl-quote tr:nth-child(odd) {
  background: #e1f4ff;
}

.tbl-quote td {
  margin: 0;
  display: block;
}

@media only screen and (min-width: 480px) {
  .tbl-quote td {
    display: table-cell;
  }

  .tbl-quote thead {
    display: table-header-group;
  }

  .tbl-quote tr {
    margin: 0;
    display: table-row;
    padding: 0;
  }
}

.tbl-quote tbody {
}

.quotes-notes-container {
  margin: 2rem 0;
}

.quotes-notes-container label {
  font-weight: bold;
}
.quotes-notes {
}

.quotes-acceptance {
  text-align: center;
}

.tbl-quote .col-line-no {
  display: none;
}

@media only screen and (min-width: 480px) {
  .tbl-quote .col-line-no {
    display: table-cell;
  }
}
