/**
 * Filters
**/

.filters-panel {
  border-radius: 5px;
  display: flex;
  background: #e6e6e6;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
  visibility: hidden;
}

.filters-panel.open {
  visibility: visible;
}

.filters-panel .dropdown {
  flex: 1;
  margin: 0 1rem;
}

@media only screen and (max-width: 1280px) {
  .filters-panel {
    flex-direction: column;
  }
}
