/**
 * Sidebar
**/

.sidebar {
  background-color: #f8fafb;
  width: 303px;
  padding-top: 8px;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

.sidebar svg {
  display: block;
  color: rgba(0, 0, 0, 0.87);
  fill: rgb(117, 117, 117);
  height: 24px;
  width: 24px;
  user-select: none;
  margin-right: 25px;
}

.sidebar ul {
  list-style: none;
  margin: 7px 0 0 15px;
  padding: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: 3px 0 3px 0;
}

.sidebar a {
  display: inline-block;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  line-height: 3rem;
  font-size: 16px;
  padding-left: 0.5rem;
  transition: padding-left 0.45s;
}

.sidebar a:hover {
  /*padding-left: 1rem;*/
  text-decoration: none;
  color: #cd1313;
}
